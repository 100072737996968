import React from "react";
import { FooterWrapper, EndFooter } from "./styles";
import s2 from "../../assets/img/s2.svg";


const Footer = props => (
  <>
    <FooterWrapper darker={props.darker}>
      <div className="columns-holder">
        <div>
          <h4>Horário de atendimento</h4>
          <div className="texto">
            <p>De segunda à sexta das 08:00 às 19:00,</p>
            <p>Sábados das 08:00 à 12:00.</p>
          </div>
        </div>
        <div>
          <h4>Ponta Grossa</h4>
          <div className="texto">
            <p id="telefone">
              <span id="telefone">Telefone:</span> (42) 3223-5005
            </p>
            <p>
              <span>Email:</span> mkclinic@gmail.com
            </p>
            <p>
              <span>Endereço:</span> R. Padre Ildefonso, 475
              <br /> Centro, Ponta Grossa - PR, 84010-070
            </p>
          </div>
          <div className="button">
            <a
              href="https://goo.gl/maps/HXEKeJx1Cy7qew2u6"
              target="_blank"
              rel="noopener noreferrer"
            >Ver no Google Maps</a>
          </div>
        </div>
        <div>
          <h4>Espaço Prime</h4>
          <div className="texto">
            <p id="telefone">
              <span id="telefone">Telefone:</span> (42) 3223-5005
            </p>
            <p>
              <span>Email:</span> mkclinic@gmail.com
            </p>
            <p>
              <span>Endereço:</span> R. Padre Ildefonso, 475
              <br /> Centro, Ponta Grossa - PR, 84010-070
            </p>
          </div>
          <div className="button">
            <a
              href="https://goo.gl/maps/HXEKeJx1Cy7qew2u6"
              target="_blank"
              rel="noopener noreferrer"
            >Ver no Google Maps</a>
          </div>
        </div>
        <div>
          <h4>Jaguariaíva</h4>
          <div className="texto">
            <p id="telefone">
              <span id="telefone">Telefone:</span> (43) 3535-2668
            </p>
            <p>
              <span>Email:</span> mkclinic@gmail.com
            </p>
            <p>
              <span>Endereço:</span> R. Amando Ribas, 940,
              <br /> Jaguariaíva - PR, 84200-000
            </p>
          </div>
          <div className="button">
            <a
              href="https://g.page/MKClinica?share"
              target="_blank"
              rel="noopener noreferrer"
            >Ver no Google Maps</a>
          </div>
        </div>
      </div>
    </FooterWrapper>
    <EndFooter>
      <div className="text-responsible">
        <h4>Responsável Técnico</h4>
        <p>Marcus Vinicius G. B. C. Mesquita - CRM: 21059</p>
      </div>
      <div className="socials">
        <a
          href="https://www.facebook.com/MK-Clinic-Clinica-de-Radiologia-386170008468898/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../../assets/img/facebook_icon.svg")}
            alt="facebook mkclinic"
          />
        </a>
        <a
          href="https://www.instagram.com/mkclinicpg/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../../assets/img/instagram_icon.svg")}
            alt="instagram mkclinic"
          />
        </a>
      </div>
      <div className="copyright">
        Desenvolvido com <img src={s2} alt="amor" /> por{" "}
        <a
          href="http://agenciaade.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          ade.ag
        </a>
      </div>
    </EndFooter>
  </>
);
export default Footer;
